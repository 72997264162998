import styled, { css } from 'styled-components';

const Site = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  flex: 1 0 auto;
`;

const MenuWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 150;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    will-change: opacity;
    pointer-events: none;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
  }

  ${props =>
    props.menuVisible &&
    css`
      pointer-events: auto;

      &:after {
        opacity: 1;
        pointer-events: auto;
      }

      ${Menu} {
        -webkit-transform: none;
        transform: none;
      }
    `}

  ${props =>
    props.animatable &&
    css`
      ${Menu} {
        transition: all 130ms ease-in;
      }
    `}


  ${props =>
    props.menuVisible &&
    props.animatable &&
    css`
      ${Menu} {
        transition: all 330ms ease-out;
      }
    `}
`;

const Menu = styled.div`
  background-color: #294f79;
  color: #fff;
  position: relative;
  width: 270px;
  height: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  -webkit-transform: translateX(-103%);
  transform: translateX(-103%);
  display: flex;
  flex-direction: column;
  will-change: transform;
  z-index: 160;
  pointer-events: auto;
`;

export { Site, Content, MenuWrapper, Menu };
