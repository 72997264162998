import React, { Component } from 'react';
import Link from 'next/link';
import {
  Wrapper,
  Container,
  Nav,
  LogoWrapper,
  LinkWrapper,
  Item,
  Social,
  TrademarkContainer,
  Trademark
} from './styles';
import Logo from './Logo';
import FBLogo from './FBLogo';
import TwitterLogo from './TwitterLogo';

class Footer extends Component {
  render() {
    return (
      <Wrapper>
        <Container>
          <Link href="/">
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          </Link>
          <Nav>
            <Item>
              <LinkWrapper href="/#about-us">About Us</LinkWrapper>
            </Item>
            <Item>
              <Link href="/locations">
                <LinkWrapper>Locations</LinkWrapper>
              </Link>
            </Item>
            <Item>
              <Link href="/privacy">
                <LinkWrapper>Terms / Privacy</LinkWrapper>
              </Link>
            </Item>
            <Item>
              <Link href="/contact">
                <LinkWrapper>Contact</LinkWrapper>
              </Link>
            </Item>
          </Nav>
          <Social>
            <a href="https://www.facebook.com/sjtpreparation/" target="_blank">
              <FBLogo />
            </a>
            <a href="https://twitter.com/SJTpreparation" target="_blank">
              <TwitterLogo />
            </a>
          </Social>
        </Container>
        <Trademark>
          <TrademarkContainer>
            <p>
              ™SJT Preparation is a registered trademark of SJT Preparation Ltd. All website content and material is
              subject to copyright ©
            </p>
          </TrademarkContainer>
        </Trademark>
      </Wrapper>
    );
  }
}

export default Footer;
