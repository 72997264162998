import styled from 'styled-components';

const Wrapper = styled.nav`
  ul {
    margin: 0;
    padding: 0;
  }

  ul li {
    border-bottom: 1px solid #c7c7c7;
  }

  ul li a {
    display: block;
    height: 50px;
    text-decoration: none;
    color: white;
    padding: 16px;
  }
`;

const Button = styled.button`
  text-transform: uppercase;
  background: #14b34f;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.2);
  padding: 11px 60px;
  margin: 16px 16px 0;
  width: 238px;
`;

export { Wrapper, Button };
