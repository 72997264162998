import React, { Component } from 'react';
import Link from 'next/link';
import { Wrapper, Button } from './styles';

class Nav extends Component {
  render() {
    return (
      <Wrapper>
        <ul>
          <li>
            <Link href="/">
              <a>Home</a>
            </Link>
          </li>
          <li>
            <Link href="/#about-us">
              <a>About us</a>
            </Link>
          </li>
          <li>
            <Link href="/course-structure">
              <a>Course Content</a>
            </Link>
          </li>
          <li>
            <Link href="/feedback">
              <a>Feedback</a>
            </Link>
          </li>
          <li>
            <Link href="/locations">
              <a>Locations</a>
            </Link>
          </li>
          <li>
            <Link href="/contact">
              <a>Contact</a>
            </Link>
          </li>
        </ul>
        <Link href="book">
          <a>
            <Button>Book Now</Button>
          </a>
        </Link>
      </Wrapper>
    );
  }
}

export default Nav;
