import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  margin: 0 16px;
`;

const FBLogo = () => (
  <Svg width={34} height={34}>
    <image
      width={34}
      height={34}
      xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAQAAABLCVATAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfhBQIVNAzbVPs7AAACMUlEQVRIx62WP2taYRTGf95IOhi06dIUFJqlhCjUwYKkQjq6NH6B2qEWCqVCv0KhQ5fSQs2YqZIPkC6ODpJkEGIlUukQAymEYodEUKIQnw7inxvv9TbNPe9073POj/Pwvrzv8QibCJMkwQohfECbE+qUKFCzTvdYgOZJ84Yofcoc0qAF+FkmQgyDCjm+0puq0tWV0rGkotIKTGkBpVWUdKzUVc38uaC8pD3FxYwV156kvBbsQEFV1VVWnpkYhDzKqquqglagoI7UdOjF3FdTR2PU2FRVTa3+MwahVTVVHRoc/syre41uxl11lZ8EpSRlr41BKCsNdtAjmOcnp6wxPFKPeM8t7OMPLzkbnkN2uccDeghlJJOtj3KKJyZ7UmZg7UBFU7ufLYv7+qXf6kyBUFEHwiBMlC2cYpslgtzltYW2RZSwlyR9dhwwdZ5zaavu0CfpJUGZcwfQNy6BTR5zx0I9p0zCywq7jsY6ALxizkY/ZM0gRMMRNIg5W6VByMBHa2b5Mxb5AMAii2xa5rTweR37aI8O3xmwZJfmpY1/Jugp99lnH3gLxCxz/LS9nLA8E/QCeMc+8Mk2Z5kTgzoRR3tOEaFuUCJG4EaYADFKBgUMNm4E2sCgYFCjQuZGoAwVagaQY534hPDDobDH6cRXnHVyWF5s4McwlV5wAdwegTojxXSxuXLVunz5u/gcufZADlCuPNkDg64MEcMd/K+xxm7QyvLQZtD6zherQcvj1uj3F84hDmcuVLbaAAAAAElFTkSuQmCC"
    />
  </Svg>
);

export default FBLogo;
