import React, { Component } from 'react';
import Link from 'next/link';
import { Wrapper, Container, LogoWrapper, LinkWrapper, Nav, List, Button, MenuIconWrapper } from './styles';
import Logo from './Logo';
import MenuIcon from './MenuIcon';

class Header extends Component {
  render() {
    const { handleMenuToggle } = this.props;

    return (
      <Wrapper>
        <Container>
          <MenuIconWrapper onClick={handleMenuToggle}>
            <MenuIcon />
          </MenuIconWrapper>
          <Link href="/">
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          </Link>
          <Nav>
            <List>
              <li>
                <Link href="/">
                  <LinkWrapper>Home</LinkWrapper>
                </Link>
              </li>
              <li>
                <LinkWrapper href="/#about-us">About Us</LinkWrapper>
              </li>
              <li>
                <Link href="/course-structure">
                  <LinkWrapper>Course Content</LinkWrapper>
                </Link>
              </li>
              <li>
                <Link href="/feedback">
                  <LinkWrapper>Feedback</LinkWrapper>
                </Link>
              </li>
              <li>
                <Link href="/locations">
                  <LinkWrapper>Dates</LinkWrapper>
                </Link>
              </li>
              <li>
                <Link href="/contact">
                  <LinkWrapper>Contact</LinkWrapper>
                </Link>
              </li>
              <li>
                <Link href="/book">
                  <a>
                    <Button>Book Now</Button>
                  </a>
                </Link>
              </li>
            </List>
          </Nav>
        </Container>
      </Wrapper>
    );
  }
}

export default Header;
