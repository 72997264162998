import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  margin: 0 16px;
`;

const TwitterLogo = () => (
  <Svg width={34} height={34}>
    <image
      width={34}
      height={34}
      xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAQAAABLCVATAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfhBQIVNAzbVPs7AAACT0lEQVRIx62WP2gTcRTHvzk1oinJ4OCSDBkEaVLsEEoHJycXyVIEkQzSwUHj0t3FRVykqAhCFw1uEnByUEghEgLFaqg2ODSBYEFSl2A0UePH4fLvLr9LW7nfTe99331579297+8FkMdJ6KLO66xiCklqq6Gqinqlj+bwgIEoqIxual5/taEt1dSSFFZcSaVk6b0e6pl+TbyF+0lTBwpkiExgETIUgDppN+Y0Z8gBJRbRlGeREpBjxosoSoUuWQJTaYQIkKVLhaiJKMoOzX1ycebVZGdENSqqQpPZA9MIMUuTyqDAgTNH9xDZjLLqkhsnSgPZQ9MIkQX7CwoRpE7pAC02t71EnaBNtAz7lmUxR4pjxvJg2SbapOACL7jsOaoA7LLENe640AKbSCSAjCvdH9wes0M0GJ09rnPaEZ8BEmKFnmsYTgDwYhh+Gee558ooQo8Vkac8UfcWAN+5zwJHueWgeWnoU5m82GbN5Q5yhd7wxQ57DqJVA9Ea25ZiqrkE4YieqDO0juuUA/1iEKOaYpZCarncP/VaJ730TkWDr6WQZQy+oU8eNF9VNgOW2gpPeHf1VN+M8avqGbxhtS01FDdAn12dGfTnkZE+roalqpIGKK+0yvrj8P3W1Yl+2iepqqWiUooYwLd647A7WtK6kSailIqmEVngMet0HH/PO+Y9B7o/IqahPcNdNvpUdZ5zCWuKMvSHdpqMhA6gUmMy4puw+Sa1wjfx9/E68u2CtKl8ubLtAn1ZIgZf8L/WGq9FK6tzHovWBz0wLVoBv1a/f6xhaqB9NrVPAAAAAElFTkSuQmCC"
    />
  </Svg>
);

export default TwitterLogo;
