import React, { Component } from 'react';
import Link from 'next/link';
import { Site, Content, MenuWrapper, Menu } from './styles';
import Nav from './Nav';
import Header from './Header';
import Footer from './Footer';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.menu = React.createRef();
  }

  state = {
    menuVisible: false,
    animatable: false
  };

  componentDidMount() {
    this.menu.current.addEventListener(
      'transitionend',
      () => {
        this.setState({ animatable: false });
      },
      false
    );
  }

  handleMenuToggle = () => {
    this.setState(({ menuVisible, animatable }) => ({
      menuVisible: !menuVisible,
      animatable: !animatable
    }));
  };

  render() {
    const { menuVisible, animatable } = this.state;

    return (
      <>
        <MenuWrapper {...{ menuVisible, animatable }} onClick={this.handleMenuToggle} ref={this.menu}>
          <Menu>
            <Nav />
          </Menu>
        </MenuWrapper>
        <Site>
          <Header {...{ handleMenuToggle: this.handleMenuToggle }} />
          <Content>{this.props.children}</Content>
          <Footer />
        </Site>
      </>
    );
  }
}

export default Layout;
