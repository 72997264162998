import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 21px;
  height: 19px;
  position: absolute;
  top: 15px;
  left: 18px;

  @media (min-width: 1080px) {
    display: none;
  }
`;

const Path = styled.path`
  fill: #6d6d6d;
`;

const MenuIcon = () => (
  <Svg viewBox="0 0 46 40.1">
    <Path d="M42 16.1H4c-2.2 0-4 1.8-4 4s1.8 4 4 4h38c2.2 0 4-1.8 4-4s-1.8-4-4-4zM42 .1H4c-2.2 0-4 1.8-4 4s1.8 4 4 4h39c1.7 0 3-1.3 3-3v-1c0-2.2-1.8-4-4-4zM42 32.1H4c-2.2 0-4 1.8-4 4s1.8 4 4 4h38c2.2 0 4-1.8 4-4s-1.8-4-4-4z" />
  </Svg>
);

export default MenuIcon;
