import styled from 'styled-components';

const Wrapper = styled.header`
  flex: 0 0 auto;
  height: 50px;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.24);

  @media (min-width: 1080px) {
    height: 80px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 15px 18px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 1400px;

  @media (min-width: 1080px) {
    justify-content: space-between;
  }
`;

const LogoWrapper = styled.a`
  margin-top: 3px;
  cursor: pointer;
`;

const LinkWrapper = styled.a`
  text-decoration: none;
  color: #294f79;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Nav = styled.nav`
  display: none;

  @media (min-width: 1080px) {
    display: block;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline;
    margin-left: 30px;
    font-size: 14px;
    font-weight: 300;
    color: #294f79;
  }
`;

const Button = styled.button`
  text-transform: uppercase;
  background: #14b34f;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  box-shadow: 0 3px 1px rgba(0, 0, 0, 0.2);
  padding: 14px 29px;
  cursor: pointer;
`;

const MenuIconWrapper = styled.a`
  @media (min-width: 1080px) {
    display: none;
  }
`;

export { Wrapper, Container, LogoWrapper, LinkWrapper, Nav, List, Button, MenuIconWrapper };
