import styled from 'styled-components';

const Wrapper = styled.footer`
  background: #1c395b;
  padding-top: 35px;
  text-align: center;
  color: white;

  @media (min-width: 768px) {
    padding-top: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 18px;
  max-width: 1140px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 58px 18px;
  }
`;

const Nav = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const LogoWrapper = styled.a`
  cursor: pointer;
`;

const LinkWrapper = styled.a`
  text-decoration: none;
  color: #fff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Item = styled.li`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 25px;

  @media (min-width: 768px) {
    display: inline;
    margin: 0 20px;
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 22px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const Trademark = styled.div`
  background: #13283e;
  padding: 17px 0px;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  opacity: 0.7;
  text-align: left;

  @media (min-width: 768px) {
    font-size: 13px;
    padding: 22px 0px;
  }
`;

const TrademarkContainer = styled.div`
  margin: 0 auto;
  padding: 0 18px;
  max-width: 1140px;
`;

export { Wrapper, Container, Nav, LogoWrapper, LinkWrapper, Item, Social, TrademarkContainer, Trademark };
