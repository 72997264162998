import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 142px;
  height: 24px;
  margin-bottom: 25px;

  @media (min-width: 768px) {
    width: 160px;
    height: 28px;
    margin-bottom: 0;
  }
`;

const Path = styled.path`
  fill: #fff;
`;

const Logo = () => (
  <Svg viewBox="0 0 773 133">
    <Path d="M36.8 41.5c-10.2-3.6-13.5-5.9-13.5-12.4 0-6.3 3.3-10 9.2-10 6.2 0 9.5 4 9.6 12h21.1c-.1-19.5-12-31-30.9-31-9.1 0-16.5 2.9-22 8.6C4.8 14.3 2 21.3 2 29.9c0 9.1 2.8 15.8 8.2 20.5 5.4 4.6 10.4 7 18.9 9.8C40.2 63.8 44 66.6 44 72.9c0 6.5-3.6 9.8-10.8 9.8-7.4 0-11.9-5.1-12-13.7H.1c.4 20.7 13.1 32.7 33.1 32.7 19.4 0 32.1-10.3 32.1-29.8 0-16.3-9.7-24.2-28.5-30.4zM111.3 68.9c0 9.1-3 13.7-9 13.7-6.2 0-9.4-5.4-9.8-16.2H71.7c.5 23.6 11.4 35.2 30.2 35.2 19.1 0 30.2-12.1 30.2-33.3V1.5h-20.9v67.4zM140.2 20.5h23.7v79.7h20.8V20.5h23.5v-19h-68z" />
    <Path d="M253 1.5h-21.1L218 100.2h4.9l6.2-44.1h16.5c18.3 0 30.6-8.1 33.1-25.5l.5-3.7c2.6-16.8-7.8-25.4-26.2-25.4zM274.3 27l-.4 3.4c-2.1 14.3-12.8 21-28.2 21h-16l6.5-45.3h16.2c15.8 0 24 6.5 21.9 20.9zM302.3 36.9c-3.3 2.4-5.5 4.9-6.9 7.7l1.5-10.7h-4.4l-9.4 66.4h4.6l6.1-43c.7-4.9 2.8-9.2 6.6-13.3 3.8-4.1 7.8-6.2 11.6-6.2.9 0 1.6 0 2.1.1l.5-4.5c-.5-.1-1.3-.1-2.2-.1-3.4-.1-6.8 1-10.1 3.6zM347.1 32.6c-13.7 0-22.6 9.2-24.7 24l-2.9 20.5c-2.1 14.8 5.1 24.3 18.7 24.3s21.6-8.1 24.2-21.4h-4.6c-1.7 10.7-8.4 16.9-19.4 16.9-11 0-16-7.1-14.3-19.4l1.3-9.5h38.7l1.5-11c2-15.1-4.3-24.4-18.5-24.4zm14 23.8l-1.1 7.1h-33.9l.9-6.9c1.7-12.3 8.7-19.7 19.7-19.7 11.5.1 16.3 6.6 14.4 19.5zM408.8 32.6c-9.2 0-16 3.6-20.3 10.6l1.3-9.4h-4.4l-13.9 98.7h4.6l5.9-41.4c2.9 6.9 8.7 10.3 17.4 10.3 13.3 0 22.8-9.4 24.8-24l2.8-20.5c2.2-14.6-4.9-24.3-18.2-24.3zm13.7 24.3l-2.8 20.2C418.2 89.3 410.5 97 399 97s-16.6-7.7-15-19.9l2.9-20.2c1.6-12.1 9.2-19.8 20.7-19.8 11.6 0 16.6 7.5 14.9 19.8zM486.9 42.9c-2.9-6.9-8.7-10.3-17.4-10.3-13.3 0-22.8 9.4-24.8 24l-2.8 20.5c-2.1 14.7 5 24.3 18.3 24.3 9.2 0 16-3.6 20.3-10.6l-1.3 9.4h4.4l9.2-66.4h-4.6l-1.3 9.1zm-1.8 14l-2.9 20.2c-1.6 12.1-9.2 19.8-20.7 19.8-11.6 0-16.6-7.5-14.9-19.8l2.8-20.2C450.9 44.8 458.5 37 470 37c11.5 0 16.7 7.6 15.1 19.9zM522.4 36.9c-3.3 2.4-5.5 4.9-6.9 7.7l1.5-10.7h-4.4l-9.4 66.4h4.6l6.1-43c.7-4.9 2.8-9.2 6.6-13.3 3.8-4.1 7.8-6.2 11.6-6.2.9 0 1.6 0 2.1.1l.5-4.5c-.5-.1-1.3-.1-2.2-.1-3.3-.1-6.8 1-10.1 3.6zM584.9 42.9c-2.9-6.9-8.7-10.3-17.4-10.3-13.3 0-22.8 9.4-24.8 24l-2.8 20.5c-2.1 14.7 5 24.3 18.3 24.3 9.2 0 16-3.6 20.3-10.6l-1.3 9.4h4.4l9.2-66.4h-4.6l-1.3 9.1zm-1.9 14l-2.9 20.2c-1.6 12.1-9.2 19.8-20.7 19.8-11.6 0-16.6-7.5-14.9-19.8l2.8-20.2C548.8 44.8 556.5 37 568 37s16.6 7.6 15 19.9zM621.3 9.9h-4.8l-3.4 23.9h-10.7l-.5 4h10.7l-7 48.7c-1.5 9.8 2.1 14.7 10.7 14.7 3.4 0 6.2-.4 8.2-1.3l.8-4.4c-2.4.9-4.9 1.5-7.7 1.5-5.9 0-8.4-2.6-7.3-10.4l7-48.7h16l.5-4h-16l3.5-24z" />
    <Path d="M653.8 4.8c-2 0-4 1.7-4.1 3.8-.4 2.1 1.1 4 3.3 4 2.1 0 4-1.6 4.4-3.6.3-2.2-1.4-4.2-3.6-4.2z" />
    <Path d="M638.3 100.2h4.6l9.4-66.4h-4.6zM694.7 32.6c-14.3 0-23.6 9.2-25.6 24l-2.8 20.5c-2.1 14.7 5.4 24.3 19.3 24.3 14.3 0 23.6-9.2 25.6-24l2.9-20.5c2-14.6-5.3-24.3-19.4-24.3zm14.8 24.3l-2.8 20.2c-1.7 12.1-9.4 19.8-20.9 19.8s-16.6-7.5-14.9-19.8l2.8-20.2c1.6-12.1 9.2-19.9 20.7-19.9 11.6 0 16.7 7.4 15.1 19.9zM755.8 32.6c-9.1 0-15.3 4.9-18.3 10.2l1.3-9h-4.6l-9.2 66.4h4.6l6.2-44.2c.7-5.4 2.9-9.9 6.6-13.5 3.8-3.6 8.1-5.4 12.8-5.4 9.9 0 14 7.3 12.4 18.5l-6.3 44.6h4.8l6.3-45.1c1.6-13.4-5-22.5-16.6-22.5z" />
  </Svg>
);

export default Logo;
