import React from 'react';
import App from 'next/app';
import Router from 'next/router';
import Head from 'next/head';
import { createGlobalStyle } from 'styled-components';
import * as Sentry from '@sentry/browser';
import Layout from '../components/Layout';

Sentry.init({
  dsn: 'https://c768bdc635b742d9afaac5c826670903@sentry.io/1462583'
});

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  html, body {
    height: 100%;
    background: #FFF;
    color: #444;
    font-size: 16px;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }
  #__next {
    height: 100%;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Ubuntu', sans-serif;
  }
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
  .bold {
    font-weight: 700;
  }
  .underline {
    text-decoration: underline;
  }
`;

class MyApp extends App {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Head>
          <title>SJT Preparation</title>
          <meta
            name="description"
            content="The most popular provider of SJT courses. Designed by doctors for final year medical students. Nationwide courses."
          />
          <meta
            name="keywords"
            content="SJT Preparation, sjt, sjt revision,situational judgment test, sjt courses, free sjt"
          />
          <meta name="viewport" content="width=device-width" />
        </Head>
        <Layout>
          <GlobalStyle />
          <Component {...pageProps} />
        </Layout>
      </>
    );
  }
}

export default MyApp;
